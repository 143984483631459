import { combineReducers } from 'redux';

import auth from './auth';
import userData from './userData';
import users from './users';
import bars from './bars';
import roles from './roles';
import devices from './devices';
import orders from './orders';
import categories from './categories';
import menuItems from './menuItems';
import discounts from './discounts';
import statistics from './statistics';

export default combineReducers({
  auth,
  userData,
  users,
  bars,
  roles,
  devices,
  orders,
  categories,
  menuItems,
  discounts,
  statistics
});
