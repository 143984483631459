import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';

const authStart = () => ({
  type: actionTypes.AUTH_START
});

const authSuccess = data => ({
  type: actionTypes.AUTH_SUCCESS,
  data
});

const authFail = (error) => ({
  type: actionTypes.AUTH_FAIL,
  error
});

const authUpdateProfile = data => ({
  type: actionTypes.AUTH_UPDATE_PROFILE,
  data
});

const toggleShowMenuStatus = data => ({
  type: actionTypes.AUTH_UPDATE_SHOW_MENU,
  data
});

export const authUpdateProfileInfo = (data) => dispatch => {
  localStorage.setItem('smartbar-first-name', data.firstname);
  localStorage.setItem('smartbar-last-name', data.lastname);
  localStorage.setItem('smartbar-image', data.image);
  dispatch(authUpdateProfile(data));
};

export const logout = () => {
  localStorage.removeItem('smartbar-key-expiration-date');
  localStorage.removeItem('smartbar-authKey');
  localStorage.removeItem('smartbar-role');
  localStorage.removeItem('smartbar-first-name');
  localStorage.removeItem('smartbar-last-name');
  localStorage.removeItem('smartbar-image');
  localStorage.removeItem('smartbar-show-menu');

  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const auth = (email, password, rememberMe) => dispatch => {
  dispatch(authStart());

  axios.post(`${API_URL}/login`, { email, password, rememberMe })
  // eslint-disable-next-line promise/always-return
    .then(response => {
      const expirationDate = new Date(new Date().getTime() + (response.data.expiresIn * 1000));
      localStorage.setItem('smartbar-key-expiration-date', expirationDate);
      localStorage.setItem('smartbar-authKey', response.data.authKey);
      localStorage.setItem('smartbar-first-name', response.data.firstName);
      localStorage.setItem('smartbar-last-name', response.data.lastName);
      localStorage.setItem('smartbar-image', response.data.image);
      localStorage.setItem('smartbar-role', response.data.role);
      localStorage.setItem('smartbar-remember', response.data.remember);
      localStorage.setItem('smartbar-show-menu', response.data.showMenu);
      // eslint-disable-next-line promise/always-return
      if (!response.data.remember) {
        dispatch(checkAuthTimeout(response.data.expiresIn));
      }

      dispatch(authSuccess(response.data));
    })
    .catch(error => {
      dispatch(authFail(error.response ? error.response.data.message : 'Server is not responding'));
    });
};

const checkAuthTimeout = (expirationTime) => dispatch => {
  setTimeout(() => dispatch(logout()), expirationTime * 1000);
};

export const authCheckState = () => dispatch => {
  const authKey = localStorage.getItem('smartbar-authKey');
  if (!authKey) {
    dispatch(logout());
  } else {
    const expirationDate = new Date(localStorage.getItem('smartbar-key-expiration-date'));
    const role = localStorage.getItem('smartbar-role');
    const firstName = localStorage.getItem('smartbar-first-name');
    const lastName = localStorage.getItem('smartbar-last-name');
    const image = localStorage.getItem('smartbar-image');
    const showMenu = (localStorage.getItem('smartbar-show-menu') === 'true');

    if ((expirationDate <= new Date()) && !(localStorage.getItem('smartbar-remember') === 'true')) {
      dispatch(logout());
    } else {
      dispatch(authSuccess({
        authKey,
        role,
        firstName,
        lastName,
        image,
        showMenu
      }));
    }
  }
};

const regStart = () => ({
  type: actionTypes.REG_START
});

const regSuccess = () => ({
  type: actionTypes.REG_SUCCESS
});

const regFail = error => ({
  type: actionTypes.REG_FAIL,
  error
});

export const reg = (email, password) => dispatch => {
  dispatch(regStart());
  const regData = {
    email,
    password
  };
  axios.post(`${API_URL}/register`, regData)
    .then(({ data }) => {
      dispatch(regSuccess());
      // eslint-disable-next-line promise/always-return
      if (data.success) {
        dispatch(auth(email, password));
      }
    })
    .catch(error => dispatch(regFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const toggleMenuStatus = (
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(authStart());
  const url = `${API_URL}/admin/toggle-menu-status/`;
  axios.get(url, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      dispatch(toggleShowMenuStatus(data));
      if ('showMenu' in data) {
        localStorage.setItem('smartbar-show-menu', data.showMenu);
      }
      return callback(data);
    })
    .catch(error => dispatch(authFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const cleanError = () => ({
  type: actionTypes.CLEAN_AUTH_ERROR
});
