import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  doneAmount: 0,
  cancelAmount: 0,
  total: 0,
  tipsList: [],
  sellingItems: [],
  filterDate: [],
  loading: false,
  error: null,
};

const getStatisticsStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getStatisticsSuccess = (state, action) => (
  updateObject(state, {
    doneAmount: action.doneAmount,
    cancelAmount: action.cancelAmount,
    total: action.total,
    tipsList: action.tipsList !== null ? action.tipsList : [],
    sellingItems: action.sellingItems !== null ? action.sellingItems : [],
    filterDate: action.filterDate,
    loading: false
  })
);

const getStatisticsFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleanStatisticsError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.ADMIN_GET_STATISTICS_START:
        return getStatisticsStart(state, action);
      case actionTypes.ADMIN_GET_STATISTICS_SUCCESS:
        return getStatisticsSuccess(state, action);
      case actionTypes.ADMIN_GET_STATISTICS_FAIL:
        return getStatisticsFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_STATISTICS_ERROR:
        return cleanStatisticsError(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
