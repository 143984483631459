/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';
import { logout } from './auth';

const getAllOrdersStart = () => ({
  type: actionTypes.ADMIN_GET_ALL_ORDERS_START
});

const getAllOrdersSuccess = (orders, statuses, bartenders) => ({
  type: actionTypes.ADMIN_GET_ALL_ORDERS_SUCCESS,
  orders,
  statuses,
  bartenders
});

const getOrderDetailsSuccess = (products, orderStatuses, ordersBefore, statusOnBoard) => ({
  type: actionTypes.ADMIN_GET_ORDER_DETAILS_SUCCESS,
  products,
  orderStatuses,
  ordersBefore,
  statusOnBoard,
});

const getAllOrdersFail = error => ({
  type: actionTypes.ADMIN_GET_ALL_ORDERS_FAIL,
  error
});

export const cleanOrdersError = () => ({
  type: actionTypes.CLEAN_ADMIN_GET_ALL_ORDERS_ERROR
});

export const getAllOrders = token => dispatch => {
  dispatch(getAllOrdersStart());
  axios.get(`${API_URL}/admin/orders`, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      const list = data.orders
        .filter(order => order.Bartender).map(obj => (
          obj.Bartender.User.FirstName || obj.Bartender.User.LastName ?
            `${obj.Bartender.User.FirstName} ${obj.Bartender.User.LastName}` :
            obj.Bartender.User.Email || obj.Bartender.User.Id));
      const bartendersUnique = [...new Set(list)];
      const bartenders = bartendersUnique.map(b => {
        const newKey = b.toLowerCase().replace(' ', '_');
        return {
          Id: newKey,
          Title: b
        };
      });
      dispatch(getAllOrdersSuccess(data.orders, data.statuses, bartenders));
      return bartenders;
    })
    .catch(error => {
      dispatch(getAllOrdersFail(error.response ? error.response.data.message : 'Server is not responding'));
      if (error.status === 403) {
        dispatch(logout());
      }
    });
};

export const getOrderDetails = (token, id) => dispatch => {
  dispatch(getAllOrdersStart());
  axios.get(`${API_URL}/admin/order-details/${id}`, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      dispatch(getOrderDetailsSuccess(
        data.products,
        data.statuses,
        data.ordersBefore,
        data.statusOnBoard
      ));
      return data.products;
    })
    .catch(error => {
      dispatch(getAllOrdersFail(error.response ? error.response.data.message : 'Server is not responding'));
      if (error.status === 403) {
        dispatch(logout());
      }
    });
};

