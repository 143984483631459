/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';
import { logout } from './auth';

const getAllMenuItemsStart = () => ({
  type: actionTypes.ADMIN_GET_ALL_MENU_ITEMS_START
});

const getAllMenuItemsSuccess = (menuItems) => ({
  type: actionTypes.ADMIN_GET_ALL_MENU_ITEMS_SUCCESS,
  menuItems
});

const getBartenderItemsSuccess = (items) => ({
  type: actionTypes.ADMIN_GET_BARTENDER_ITEMS_SUCCESS,
  items
});

const getAllMenuItemsFail = error => ({
  type: actionTypes.ADMIN_GET_ALL_MENU_ITEMS_FAIL,
  error
});

export const cleanMenuItemsError = () => ({
  type: actionTypes.CLEAN_ADMIN_GET_ALL_MENU_ITEMS_ERROR
});

const createMenuItemStart = () => ({
  type: actionTypes.CREATE_MENU_ITEM_START
});

const createMenuItemSuccess = () => ({
  type: actionTypes.CREATE_MENU_ITEM_SUCCESS,
});

const createMenuItemFail = error => ({
  type: actionTypes.CREATE_MENU_ITEM_FAIL,
  error
});

const searchMenuItemsSuccess = (menuItems) => ({
  type: actionTypes.ADMIN_SEARCH_MENU_ITEMS_SUCCESS,
  menuItems
});

export const getBartenderItems = (bartenderId, token) => dispatch =>
  // eslint-disable-next-line compat/compat,no-new
  new Promise((resolve, reject) => {
    dispatch(getAllMenuItemsStart());
    axios.get(`${API_URL}/admin/bartender-items/${bartenderId}`, {
      headers: {
        'X-ACCESS-TOKEN': token
      }
    })
      .then(({ data }) => {
        dispatch(getBartenderItemsSuccess(data.items));
        return resolve();
      })
      .catch(({ response }) => {
        dispatch(getAllMenuItemsFail(response.message));
        if (response.status === 403) {
          dispatch(logout());
        }
        reject(response.message);
      });
  });

export const getAllMenuItems = token => dispatch =>
  // eslint-disable-next-line compat/compat,no-new
  new Promise((resolve, reject) => {
    dispatch(getAllMenuItemsStart());
    axios.get(`${API_URL}/admin/menu-items`, {
      headers: {
        'X-ACCESS-TOKEN': token
      }
    })
      .then(({ data }) => {
        dispatch(getAllMenuItemsSuccess(data.menuItems));
        return resolve();
      })
      .catch(error => {
        dispatch(getAllMenuItemsFail(error.response ? error.response.data.message : 'Server is not responding'));
        if (error.status === 403) {
          dispatch(logout());
        }
        reject(error.response ? error.response.data.message : 'Server is not responding');
      });
  });

export const createMenuItem = (
  name,
  categoryId,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createMenuItemStart());
  if (categoryId === 0) {
    dispatch(createMenuItemFail('choose category'));
    return false;
  }
  if (name === '') {
    dispatch(createMenuItemFail('enter name of item'));
    return false;
  }
  const createData = {
    name,
    category_id: categoryId,
  };
  const url = `${API_URL}/admin/create-item`;
  axios.post(url, createData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(({ data }) => {
      dispatch(createMenuItemSuccess());
      dispatch(getAllMenuItems(token));
      return callback(data.item);
    })
    .catch(error => dispatch(createMenuItemFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const createMenuItemByBar = (
  itemByBarId,
  itemId,
  price,
  ingredients,
  features,
  image,
  barId,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createMenuItemStart());
  const createData = {
    product_id: itemId,
    price,
    ingredients,
    features,
    image,
    bar_id: barId,
  };
  const url = itemByBarId ? `${API_URL}/admin/update-item-by-bar/${itemByBarId}` : `${API_URL}/admin/create-item-by-bar`;
  axios.post(url, createData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(({ data }) => {
      dispatch(createMenuItemSuccess());
      dispatch(getAllMenuItems(token));
      return callback(data.item);
    })
    .catch(error => dispatch(createMenuItemFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const searchMenuItems = (
  categoryId,
  query,
  limit,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createMenuItemStart());
  const searchData = {
    category_id: categoryId,
    query,
    limit,
  };
  const url = `${API_URL}/admin/search-items`;
  axios.post(url, searchData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(({ data }) => {
      dispatch(searchMenuItemsSuccess(data.menuItems));
      return callback(query);
    })
    .catch(error => dispatch(createMenuItemFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const toggleItem = (
  itemId,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createMenuItemStart());
  const url = `${API_URL}/admin/toggle-status-product/${itemId}`;
  axios.get(url, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      dispatch(getAllMenuItems(token));
      return callback(data);
    })
    .catch(error => dispatch(createMenuItemFail(error.response ? error.response.data.message : 'Server is not responding')));
};
export const toggleAllItems = (
  status,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createMenuItemStart());
  const url = `${API_URL}/admin/toggle-status-products/${status}`;
  axios.get(url, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      dispatch(getAllMenuItems(token));
      return callback(data);
    })
    .catch(error => dispatch(createMenuItemFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const toggleCheckedItems = (
  status,
  checkboxes,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createMenuItemStart());
  const products = {
    status,
    productIds: checkboxes,
  };
  const url = `${API_URL}/admin/toggle-products`;
  axios.post(url, products, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      dispatch(getAllMenuItems(token));
      return callback(data);
    })
    .catch(error => dispatch(createMenuItemFail(error.response ? error.response.data.message : 'Server is not responding')));
};
export const deleteProduct = (
  itemId,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createMenuItemStart());
  const url = `${API_URL}/admin/delete-product/${itemId}`;
  axios.get(url, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      dispatch(getAllMenuItems(token));
      return callback(data);
    })
    .catch(error => dispatch(createMenuItemFail(error.response ? error.response.data.message : 'Server is not responding')));
};

