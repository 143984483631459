import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  authKey: null,
  role: '',
  firsName: null,
  lastName: null,
  image: null,
  error: null,
  loading: false,
  showMenu: false
};

const authStart = state => (
  updateObject(state, {
    error: null,
    loading: true
  })
);

const authSuccess = (state, action) => (
  updateObject(state, {
    authKey: action.data.authKey,
    role: action.data.role,
    firstName: action.data.firstName,
    lastName: action.data.lastName,
    image: action.data.image,
    showMenu: action.data.showMenu,
    loading: false
  })
);

const authUpdateProfile = (state, action) => (
  updateObject(state, {
    firstName: action.data.firstname,
    lastName: action.data.lastname,
    image: action.data.image,
  })
);

const authUpdateShowMenuStatus = (state, action) => (
  updateObject(state, {
    showMenu: action.data.showMenu,
  })
);

const authFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const authLogout = state => (
  updateObject(state, {
    authKey: null,
    role: '',
    firstName: null,
    lastName: null,
    image: null
  })
);

const regStart = state => (
  updateObject(state, {
    error: null,
    loading: true
  })
);

const regSuccess = state => (
  updateObject(state, {
    loading: false
  })
);

const regFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleanError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.AUTH_START: return authStart(state, action);
      case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
      case actionTypes.AUTH_UPDATE_PROFILE: return authUpdateProfile(state, action);
      case actionTypes.AUTH_FAIL: return authFail(state, action);
      case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
      case actionTypes.REG_START: return regStart(state, action);
      case actionTypes.REG_SUCCESS: return regSuccess(state, action);
      case actionTypes.REG_FAIL: return regFail(state, action);
      case actionTypes.AUTH_UPDATE_SHOW_MENU: return authUpdateShowMenuStatus(state, action);
      case actionTypes.CLEAN_AUTH_ERROR: return cleanError(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
