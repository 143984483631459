import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { Route } from 'react-router4-with-layouts';
import { connect } from 'react-redux';
import asyncComponent from './hoc/asyncComponent/asyncComponent';
//  "lazyload" of components
import Layout from './hoc/Layout/Layout';
import AdminLayout from './hoc/AdminLayout/AdminLayout';
import ContentLayout from './hoc/ContentLayout/ContentLayout';
import classes from './App.css';
import ContentPage from './containers/ContentPage/ContentPage';
import Homepage from './containers/Homepage/Homepage';

import { authCheckState } from './store/actions/index';

const asyncSignIn = asyncComponent(() => import('./containers/Auth/SignIn'));
const asyncSignUp = asyncComponent(() => import('./containers/Auth/SignUp'));
const asyncLogout = asyncComponent(() => import('./containers/Auth/Logout/Logout'));
const asyncAdmin = asyncComponent(() => import('./containers/Admin/Admin'));
const asyncForgotPassword = asyncComponent(() => import('./containers/Auth/ForgotPassword/ForgotPassword'));
const asyncRestorePassword = asyncComponent(() => import('./containers/Auth/RestorePassword/RestorePassword'));

// const asyncMyAccount = asyncComponent(() => import('./containers/MyAccount/MyAccount'));

// const styles = {
//   root: {
//     backgroundImage: `url(${background})`,
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//   }
// };

class App extends Component {
  componentDidMount() {
    //  try auto sign up
    this.props.onTryAutoSignup();
  }
  render() {
    let routes = (
      <Switch>
        <Route exact path="/" layout={Layout} component={asyncSignIn} />
        <Route exact path="/home" layout={Layout} component={Homepage} />
        <Route exact path="/privacy-policy" layout={ContentLayout} component={ContentPage} />
        <Route exact path="/sign-in" layout={Layout} component={asyncSignIn} />
        <Route exact path="/sign-up" layout={Layout} component={asyncSignUp} />
        <Route path="/forgot-password" layout={Layout} component={asyncForgotPassword} />
        <Route path="/restore-password/:hash" layout={Layout} component={asyncRestorePassword} />

        <Redirect to="/" />
      </Switch>
    );

    //  routes for authorized user
    if (this.props.isAuth) {
      routes = (
        <div>
          <Switch>
            <Route exact path="/sign-in" layout={Layout} component={asyncSignIn} />
            <Route exact path="/sign-up" layout={Layout} component={asyncSignUp} />
            <Route exact path="/privacy-policy" layout={ContentLayout} component={ContentPage} />
            <Route path="/logout" layout={Layout} component={asyncLogout} />
            <Route path="/admin" layout={AdminLayout} component={asyncAdmin} />

            <Redirect from="/sign-in" to="/admin" />
            <Redirect from="/sign-up" to="/admin" />
            <Redirect from="/" to="/admin" />
          </Switch>
        </div>
      );
    }

    return (
      <div className={classes.App}> {routes} </div>
    );
  }
}

App.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  onTryAutoSignup: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuth: state.auth.authKey !== null
});

const mapDispatchToProps = dispatch => ({
  onTryAutoSignup: () => dispatch(authCheckState())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
