import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import classes from './Sidebar.css';

const sidebar = ({
  navList, show, handleToggle, handleDropdownToggle, path
}) => (
  <nav
    className={show ? classes.Sidebar : [classes.Sidebar, classes.Minify].join(' ')}
  >
    <div className={classes.ToggleButtonSection}>
      <button onClick={() => handleToggle()}>
        {show ? 'left' : 'right'}
      </button>
    </div>
    <ul>
      {
        navList.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index}>
            {item.link ?
              <NavLink
                to={item.link}
                className={path.some(el => el === item.link.substring(item.link.lastIndexOf('/') + 1))
                  ? classes.Current
                  : ''}
              >
                <div className={[classes.IconItem, classes[item.class]].join(' ')} />
                <span style={show ? null : { display: 'none' }}>
                  {item.label}
                </span>
              </NavLink>
              :
              <button
                onClick={() => handleDropdownToggle(item.label)}
                className={item.showDropdown
                  ? classes.Current
                  : ''}
              >
                <div className={[classes.IconItem, classes[item.class]].join(' ')} />
                <span style={show ? null : { display: 'none' }}>
                  {item.label}
                </span>
              </button>
            }
            {item.dropdown ?
              <ul className={item.showDropdown ? classes.Open : ''}>
                {item.dropdown.map((listItem, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={i}>
                    <NavLink
                      to={listItem.link}
                      className={path.some(el => el === listItem.link.substring(listItem.link.lastIndexOf('/') + 1))
                        ? classes.Current
                        : ''}
                    >
                      {listItem.icon}
                      <span>
                        {listItem.label}
                      </span>
                    </NavLink>
                  </li>
                ))}
              </ul>
              :
              null
            }
          </li>
        ))
      }
    </ul>
  </nav>
);

sidebar.propTypes = {
  navList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  show: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleDropdownToggle: PropTypes.func.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default sidebar;
