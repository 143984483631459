export const REG_START = 'REG_START';
export const REG_SUCCESS = 'REG_SUCCESS';
export const REG_FAIL = 'REG_FAIL';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_UPDATE_PROFILE = 'AUTH_UPDATE_PROFILE';
export const AUTH_UPDATE_SHOW_MENU = 'AUTH_UPDATE_SHOW_MENU';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const CLEAN_AUTH_ERROR = 'CLEAN_AUTH_ERROR';

export const CREATE_START = 'CREATE_START';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_FAIL = 'CREATE_FAIL';

export const CLEAN_GET_PROFILE_DATA_ERROR = 'CLEAN_GET_PROFILE_DATA_ERROR';
export const GET_USER_LOADING_START = 'GET_USER_LOADING_START';
export const GET_USER_SHOW_MENU_SUCCESS = 'GET_USER_SHOW_MENU_SUCCESS';
export const GET_USER_LOADING_FAIL = 'GET_USER_LOADING_FAIL';

export const ADMIN_GET_ALL_USERS_START = 'ADMIN_GET_ALL_USERS_START';
export const ADMIN_GET_ALL_USERS_SUCCESS = 'ADMIN_GET_ALL_USERS_SUCCESS';
export const ADMIN_GET_ALL_BARTENDERS_SUCCESS = 'ADMIN_GET_ALL_BARTENDERS_SUCCESS';
export const ADMIN_GET_ALL_USERS_FAIL = 'ADMIN_GET_ALL_USERS_FAIL';
export const CLEAN_ADMIN_GET_ALL_USERS_ERROR = 'CLEAN_ADMIN_GET_ALL_USERS_ERROR';

export const ADMIN_GET_ACCOUNT_START = 'ADMIN_GET_ACCOUNT_START';
export const ADMIN_GET_ACCOUNT_SUCCESS = 'ADMIN_GET_ACCOUNT_SUCCESS';
export const ADMIN_GET_ACCOUNT_FAIL = 'ADMIN_GET_ACCOUNT_FAIL';
export const CLEAN_ADMIN_GET_ACCOUNT_ERROR = 'CLEAN_ADMIN_GET_ACCOUNT_ERROR';

export const ADMIN_GET_ALL_BARS_START = 'ADMIN_GET_ALL_BARS_START';
export const ADMIN_GET_ALL_BARS_SUCCESS = 'ADMIN_GET_ALL_BARS_SUCCESS';
export const ADMIN_GET_ALL_BARS_FAIL = 'ADMIN_GET_ALL_BARS_FAIL';
export const CLEAN_ADMIN_GET_ALL_BARS_ERROR = 'CLEAN_ADMIN_GET_ALL_BARS_ERROR';

export const CREATE_BAR_START = 'CREATE_BAR_START';
export const CREATE_BAR_SUCCESS = 'CREATE_BAR_SUCCESS';
export const CREATE_BAR_FAIL = 'CREATE_BAR_FAIL';

export const ADMIN_GET_ALL_ROLES_START = 'ADMIN_GET_ALL_ROLES_START';
export const ADMIN_GET_ALL_ROLES_SUCCESS = 'ADMIN_GET_ALL_ROLES_SUCCESS';
export const ADMIN_GET_ALL_ROLES_FAIL = 'ADMIN_GET_ALL_ROLES_FAIL';
export const CLEAN_ADMIN_GET_ALL_ROLES_ERROR = 'CLEAN_ADMIN_GET_ALL_ROLES_ERROR';

export const ADMIN_GET_ALL_DEVICES_START = 'ADMIN_GET_ALL_DEVICES_START';
export const ADMIN_GET_ALL_DEVICES_SUCCESS = 'ADMIN_GET_ALL_DEVICES_SUCCESS';
export const ADMIN_GET_ALL_DEVICES_FAIL = 'ADMIN_GET_ALL_DEVICES_FAIL';
export const CLEAN_ADMIN_GET_ALL_DEVICES_ERROR = 'CLEAN_ADMIN_GET_ALL_DEVICES_ERROR';

export const CREATE_DEVICE_START = 'CREATE_DEVICE_START';
export const CREATE_DEVICE_SUCCESS = 'CREATE_DEVICE_SUCCESS';
export const CREATE_DEVICE_FAIL = 'CREATE_DEVICE_FAIL';

export const ADMIN_GET_ALL_ORDERS_START = 'ADMIN_GET_ALL_ORDERS_START';
export const ADMIN_GET_ALL_ORDERS_SUCCESS = 'ADMIN_GET_ALL_ORDERS_SUCCESS';
export const ADMIN_GET_ORDER_DETAILS_SUCCESS = 'ADMIN_GET_ORDER_DETAILS_SUCCESS';
export const ADMIN_GET_ALL_ORDERS_FAIL = 'ADMIN_GET_ALL_ORDERS_FAIL';
export const CLEAN_ADMIN_GET_ALL_ORDERS_ERROR = 'CLEAN_ADMIN_GET_ALL_ORDERS_ERROR';

export const CREATE_CATEGORY_START = 'CREATE_CATEGORY_START';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL';
export const ADMIN_GET_ALL_CATEGORIES_START = 'ADMIN_GET_ALL_CATEGORIES_START';
export const ADMIN_GET_ALL_CATEGORIES_SUCCESS = 'ADMIN_GET_ALL_CATEGORIES_SUCCESS';
export const ADMIN_GET_BAR_CATEGORIES_SUCCESS = 'ADMIN_GET_BAR_CATEGORIES_SUCCESS';
export const ADMIN_GET_CATEGORIES_WITH_ITEMS_SUCCESS = 'ADMIN_GET_CATEGORIES_WITH_ITEMS_SUCCESS';
export const ADMIN_GET_ALL_CATEGORIES_BY_BAR_SUCCESS = 'ADMIN_GET_ALL_CATEGORIES_BY_BAR_SUCCESS';
export const ADMIN_GET_ALL_CATEGORIES_FAIL = 'ADMIN_GET_ALL_CATEGORIES_FAIL';
export const CLEAN_ADMIN_GET_ALL_CATEGORIES_ERROR = 'CLEAN_ADMIN_GET_ALL_CATEGORIES_ERROR';

export const CREATE_MENU_ITEM_START = 'CREATE_MENU_ITEM_START';
export const CREATE_MENU_ITEM_SUCCESS = 'CREATE_MENU_ITEM_SUCCESS';
export const CREATE_MENU_ITEM_FAIL = 'CREATE_MENU_ITEM_FAIL';
export const ADMIN_GET_ALL_MENU_ITEMS_START = 'ADMIN_GET_ALL_MENU_ITEMS_START';
export const ADMIN_GET_ALL_MENU_ITEMS_SUCCESS = 'ADMIN_GET_ALL_MENU_ITEMS_SUCCESS';
export const ADMIN_GET_BARTENDER_ITEMS_SUCCESS = 'ADMIN_GET_BARTENDER_ITEMS_SUCCESS';
export const ADMIN_GET_ALL_MENU_ITEMS_FAIL = 'ADMIN_GET_ALL_MENU_ITEMS_FAIL';
export const CLEAN_ADMIN_GET_ALL_MENU_ITEMS_ERROR = 'CLEAN_ADMIN_GET_ALL_MENU_ITEMS_ERROR';
export const ADMIN_SEARCH_MENU_ITEMS_SUCCESS = 'ADMIN_SEARCH_MENU_ITEMS_SUCCESS';

export const DISCOUNTS_LOADING = 'DISCOUNTS_LOADING';
export const DISCOUNT_ERROR = 'DISCOUNT_ERROR';
export const CLEAN_DISCOUNT_ERROR = 'CLEAN_DISCOUNT_ERROR';
export const CREATE_DISCOUNT_SUCCESS = 'CREATE_DISCOUNT_SUCCESS';
export const ADMIN_GET_ALL_DISCOUNTS_SUCCESS = 'ADMIN_GET_ALL_DISCOUNTS_SUCCESS';

export const ADMIN_GET_STATISTICS_START = 'ADMIN_GET_STATISTICS_START';
export const ADMIN_GET_STATISTICS_SUCCESS = 'ADMIN_GET_STATISTICS_SUCCESS';
export const ADMIN_GET_STATISTICS_FAIL = 'ADMIN_GET_STATISTICS_FAIL';
export const CLEAN_ADMIN_GET_STATISTICS_ERROR = 'CLEAN_ADMIN_GET_STATISTICS_ERROR';
