import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  orders: [],
  statuses: [],
  bartenders: [],
  products: [],
  orderStatuses: [],
  ordersBefore: 0,
  statusOnBoard: '',
  error: null,
  loading: false
};

const getAllOrdersStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getAllOrdersSuccess = (state, action) => (
  updateObject(state, {
    orders: action.orders,
    statuses: action.statuses,
    bartenders: action.bartenders,
    loading: false
  })
);

const getOrderDetailsSuccess = (state, action) => (
  updateObject(state, {
    products: action.products,
    orderStatuses: action.orderStatuses,
    ordersBefore: action.ordersBefore,
    statusOnBoard: action.statusOnBoard,
    loading: false
  })
);

const getAllOrdersFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleanOrdersError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.ADMIN_GET_ALL_ORDERS_START:
        return getAllOrdersStart(state, action);
      case actionTypes.ADMIN_GET_ALL_ORDERS_SUCCESS:
        return getAllOrdersSuccess(state, action);
      case actionTypes.ADMIN_GET_ORDER_DETAILS_SUCCESS:
        return getOrderDetailsSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_ORDERS_FAIL:
        return getAllOrdersFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_ALL_ORDERS_ERROR:
        return cleanOrdersError(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
