/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';
import { logout } from './auth';

const getAllCategoriesStart = () => ({
  type: actionTypes.ADMIN_GET_ALL_CATEGORIES_START
});

const getAllCategoriesSuccess = (categories, types) => ({
  type: actionTypes.ADMIN_GET_ALL_CATEGORIES_SUCCESS,
  categories,
  types
});

const getBarCategoriesSuccess = (barCategories, types) => ({
  type: actionTypes.ADMIN_GET_BAR_CATEGORIES_SUCCESS,
  barCategories,
  types
});

const getAllCategoriesWithItemsSuccess = (categories, listTypes) => ({
  type: actionTypes.ADMIN_GET_CATEGORIES_WITH_ITEMS_SUCCESS,
  categories,
  listTypes
});

const getAllCategoriesByBarSuccess = categories => ({
  type: actionTypes.ADMIN_GET_ALL_CATEGORIES_BY_BAR_SUCCESS,
  categories
});

const getAllCategoriesFail = error => ({
  type: actionTypes.ADMIN_GET_ALL_CATEGORIES_FAIL,
  error
});

export const cleanCategoriesError = () => ({
  type: actionTypes.CLEAN_ADMIN_GET_ALL_CATEGORIES_ERROR
});

const createCategoryStart = () => ({
  type: actionTypes.CREATE_CATEGORY_START
});

const createCategorySuccess = () => ({
  type: actionTypes.CREATE_CATEGORY_SUCCESS,
});

const createCategoryFail = error => ({
  type: actionTypes.CREATE_CATEGORY_FAIL,
  error
});

export const getAllCategories = token => dispatch =>
  // eslint-disable-next-line compat/compat,no-new
  new Promise((resolve, reject) => {
    dispatch(getAllCategoriesStart());
    axios.get(`${API_URL}/admin/categories`, {
      headers: {
        'X-ACCESS-TOKEN': token
      }
    })
      .then(({ data }) => {
        dispatch(getAllCategoriesSuccess(data.categories, data.types));
        return resolve();
      })
      .catch(error => {
        dispatch(getAllCategoriesFail(error.response ? error.response.data.message : 'Server is not responding'));
        if (error.status === 403) {
          dispatch(logout());
        }
        reject(error.response ? error.response.data.message : 'Server is not responding');
      });
  });

export const getBarCategories = token => dispatch =>
  // eslint-disable-next-line compat/compat,no-new
  new Promise((resolve, reject) => {
    dispatch(getAllCategoriesStart());
    axios.get(`${API_URL}/admin/bar-categories`, {
      headers: {
        'X-ACCESS-TOKEN': token
      }
    })
      .then(({ data }) => {
        dispatch(getBarCategoriesSuccess(data.barCategories, data.types));
        return resolve();
      })
      .catch(error => {
        dispatch(getAllCategoriesFail(error.response ? error.response.data.message : 'Server is not responding'));
        if (error.status === 403) {
          dispatch(logout());
        }
        reject(error.response ? error.response.data.message : 'Server is not responding');
      });
  });

export const getCategoriesWithItems = (token, barId) => dispatch =>
  // eslint-disable-next-line compat/compat,no-new
  new Promise((resolve, reject) => {
    dispatch(getAllCategoriesStart());
    axios.get(`${API_URL}/admin/categories-with-items/${barId !== undefined ? barId : 0}`, {
      headers: {
        'X-ACCESS-TOKEN': token
      }
    })
      .then(({ data }) => {
        let listTypes = [];
        if (data.categories) {
          listTypes = data.categories.filter(item => (
            item.CategoryTypeId === 1)).map(c => ({ ...c }));
          listTypes.unshift({ Id: '', Title: 'Choose from the list' });
        }
        dispatch(getAllCategoriesWithItemsSuccess(data.categories, listTypes));
        return resolve();
      })
      .catch(error => {
        dispatch(getAllCategoriesFail(error.response ? error.response.data.message : 'Server is not responding'));
        reject(error.response ? error.response.data.message : 'Server is not responding');
      });
  });

export const createCategory = (
  name,
  typeId,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createCategoryStart());
  if (typeId === '') {
    dispatch(createCategoryFail('choose category type'));
    return callback({ error: 'choose category type' });
  }
  if (name === '') {
    dispatch(createCategoryFail('enter name of category'));
    return callback({ error: 'enter name of category' });
  }
  const createData = {
    name,
    type_id: typeId,
  };
  const url = `${API_URL}/admin/create-category`;
  axios.post(url, createData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(({ data }) => {
      dispatch(createCategorySuccess());
      dispatch(getAllCategories(token));
      return callback(data.category);
    })
    .catch(error => dispatch(createCategoryFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const createCategoryByBar = (
  itemId,
  categoryId,
  features,
  settingIce,
  barId,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createCategoryStart());
  const createData = {
    category_id: categoryId,
    setting_ice: settingIce,
    bar_id: barId,
    features,
  };
  const url = itemId ? `${API_URL}/admin/update-category-by-bar/${itemId}` : `${API_URL}/admin/create-category-by-bar`;
  axios.post(url, createData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(({ data }) => {
      // dispatch(createCategoryByBarSuccess());
      dispatch(getAllCategories(token));
      return callback(data.category);
    })
    .catch(error => dispatch(createCategoryFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const searchCategoriesByBar = token => dispatch =>
  // eslint-disable-next-line compat/compat,no-new
  new Promise((resolve, reject) => {
    dispatch(getAllCategoriesStart());
    const url = `${API_URL}/admin/get-categories-by-bar`;
    axios.get(url, { headers: { 'X-ACCESS-TOKEN': token } })
      .then(({ data }) => {
        dispatch(getAllCategoriesByBarSuccess(data.categories));
        return resolve();
      })
      .catch(error => {
        dispatch(getAllCategoriesFail(error.response ? error.response.data.message : 'Server is not responding'));
        reject(error.response ? error.response.data.message : 'Server is not responding');
      });
  });

export const getCategoryByBar = (
  categoryId,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(createCategoryStart());
  const url = `${API_URL}/admin/get-category-by-bar/${categoryId}`;
  axios.get(url, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => (callback(data)))
    .catch(error => dispatch(createCategoryFail(error.response ? error.response.data.message : 'Server is not responding')));
};
