/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';
import { logout } from './auth';

const getAllRolesStart = () => ({
  type: actionTypes.ADMIN_GET_ALL_ROLES_START
});

const getAllRolesSuccess = roles => ({
  type: actionTypes.ADMIN_GET_ALL_ROLES_SUCCESS,
  roles
});

const getAllRolesFail = error => ({
  type: actionTypes.ADMIN_GET_ALL_ROLES_FAIL,
  error
});

export const cleanRolesError = () => ({
  type: actionTypes.CLEAN_ADMIN_GET_ALL_ROLES_ERROR
});

export const getAllRoles = token => dispatch => {
  dispatch(getAllRolesStart());
  axios.get(`${API_URL}/admin/roles`, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => dispatch(getAllRolesSuccess(data.roles.map((item) => ({
      Id: item.Id,
      Title: item.Role,
      Permissions: item.Permissions,
      CreatedAt: item.CreatedAt,
    })))))
    .catch(({ response }) => {
      dispatch(getAllRolesFail(response.message));
      if (response.status === 403) {
        dispatch(logout());
      }
    });
};

