import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  discounts: [],
  error: null,
  loading: false
};

const getDiscountLoading = state => (
  updateObject(state, {
    loading: true
  })
);

const getAllDiscountsSuccess = (state, action) => (
  action.discounts !== null ?
    updateObject(state, {
      discounts: action.discounts,
      loading: false
    })
    :
    updateObject(state, {
      loading: false
    })
);

const getDiscountSuccess = state => (
  updateObject(state, {
    loading: false
  })
);

const getDiscountError = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleanDiscountError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.DISCOUNTS_LOADING:
        return getDiscountLoading(state, action);
      case actionTypes.ADMIN_GET_ALL_DISCOUNTS_SUCCESS:
        return getAllDiscountsSuccess(state, action);
      case actionTypes.DISCOUNT_ERROR:
        return getDiscountError(state, action);
      case actionTypes.CLEAN_DISCOUNT_ERROR:
        return cleanDiscountError(state, action);
      case actionTypes.CREATE_DISCOUNT_SUCCESS:
        return getDiscountSuccess(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
