import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  devices: [],
  listTypes: [],
  error: null,
  loading: false
};

const getAllDevicesStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getAllDevicesSuccess = (state, action) => (
  updateObject(state, {
    devices: action.devices,
    listTypes: action.listTypes,
    loading: false
  })
);

const getAllDevicesFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const createDeviceStart = state => (
  updateObject(state, {
    error: null,
    loading: true
  })
);

const createDeviceSuccess = state => (
  updateObject(state, {
    loading: false
  })
);

const createDeviceFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleanDevicesError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.ADMIN_GET_ALL_DEVICES_START:
        return getAllDevicesStart(state, action);
      case actionTypes.ADMIN_GET_ALL_DEVICES_SUCCESS:
        return getAllDevicesSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_DEVICES_FAIL:
        return getAllDevicesFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_ALL_DEVICES_ERROR:
        return cleanDevicesError(state, action);
      case actionTypes.CREATE_DEVICE_START:
        return createDeviceStart(state, action);
      case actionTypes.CREATE_DEVICE_SUCCESS:
        return createDeviceSuccess(state, action);
      case actionTypes.CREATE_DEVICE_FAIL:
        return createDeviceFail(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
