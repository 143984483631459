// eslint-disable-next-line import/extensions
import pbkdf2 from 'pbkdf2';
import sha256 from 'js-sha256';
import aesjs from 'aes-js';
import Moment from 'moment';

import { API_URL, IP_ADDR } from './const';

const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties
});

/**
 * Run test through regexp and look on digits after decimal dot.
 * @param value {number}
 * @param digits {number}
 * @return {boolean}
 */
const decimalMiddleware = (value, digits = 3) => {
  const regexp = new RegExp(`^(\\d{0,12})+(\\.\\d{1,${digits}})?$`);
  return regexp.test(value);
};

/**
 * Encrypt csk
 * @param address {string}
 * @param amount {number||string}
 * @param csk {string}
 * @param aesJsCounter {number}
 * @return {string}
 */
const cskCipher = (address, amount, csk, aesJsCounter = 6) => {
  const regexp = /[0-9A-Fa-f]{6}/g;
  if (!address || !amount || !csk || !regexp.test(csk)) return undefined;

  const password = `${IP_ADDR}${amount}${address}`;
  const salt = sha256.hex(Buffer.from(password));

  const aesKey = pbkdf2.pbkdf2Sync(password, salt, 1, 256 / 8, 'sha512');

  const dataBytes = aesjs.utils.hex.toBytes(csk);
  // eslint-disable-next-line new-cap
  const aesCtr = new aesjs.ModeOfOperation.ctr(aesKey, new aesjs.Counter(aesJsCounter));

  const encryptedBytes = aesCtr.encrypt(dataBytes);

  return aesjs.utils.hex.fromBytes(encryptedBytes);
};

/**
 * Run func for generate columns with data for page with table
 * @param data {array}
 * @param filter {string}
 * @param value {string}
 * @param columns{object}
 * @param image_folder{string}
 * @return convert_data {array}
 */
const dataGenetateForTable = (data, columns, filter = '', value = '', image_folder = '', types = []) => {
  let convertData;
  if (filter && value) {
    convertData = data.filter(row => row[filter] === value);
  } else {
    convertData = data;
  }
  convertData = convertData.map(_obj => {
    const obj = Object.assign({}, _obj); // decouple instance
    // check Name field and create from first and last name
    if ('Name' in columns) {
      obj.Name = generateName(obj);
    }
    if ('Image' in columns) {
      obj.Image = generateUrlImage(obj.Image, image_folder);
    }
    if ('ImageSrc' in columns) {
      obj.ImageSrc = generateUrlImage(obj.ImageSrc, image_folder);
    }
    if ('CreatedAt' in columns) {
      obj.CreatedAt = convertDate(obj.CreatedAt);
    }
    if ('BoardInfo' in columns) {
      obj.BoardInfo = convertBoardPlace(obj.BoardInfo);
    }
    if ('CategoryType' in columns && types.length > 0) {
      obj.CategoryType = types.filter(el => el.Id === obj.Category.ParentId)[0].Title;
    }
    // change order of columns for table
    Object.keys(columns).forEach(col => {
      const v = obj[col];
      delete obj[col];
      // if field contains object of data
      if (typeof v === 'object') {
        // eslint-disable-next-line no-return-assign
        Object.keys(columns[col]).map(el => obj[col] = v[el]);
      } else {
        obj[col] = v;
      }
    });
    // Rename columns and delete all unnecessary fields for table
    const keyValues = Object.keys(obj).filter(col => columns[col]).map(col => {
      let newKey;
      // if field contains object of data
      if (typeof columns[col] === 'object') {
        // eslint-disable-next-line no-return-assign
        Object.keys(columns[col]).map(el => newKey = columns[col][el]);
      } else {
        newKey = columns[col] || col;
      }
      return { [newKey]: obj[col] };
    });
    return Object.assign({}, ...keyValues);
  });
  return convertData;
};

/**
 * Run func for data search in table
 * @param data {array}
 * @param search {string}
 * @param columns{object}
 * @return {array}
 */
const dataSearch = (data, columns, search = '') => (
  data.filter(row =>
    columns.map(col =>
      row[col].toLowerCase().includes(search.toLowerCase()))
      .some(elem => elem))
);

/**
 * generate Url Image
 * @param name {string}
 * @param folder {string}
 * @param size {string}
 * @return string
 */
const generateUrlImage = (name, folder, size = '100') => (
  name ? `${API_URL}/static/${folder}/${name}_${size}.png` : ''
);

/**
 * generate Name
 * @param user {object}
 * @return string
 */
const generateName = (user) => (
  `${user.Firstname} ${user.Lastname}` || user.Email || user.Id
);

/**
 * convert price
 * @param price {int}
 * @return string
 */
const convertPrice = price => (
  `$${price / 100}`
);

/**
 * convert datetime go format to time
 * @param datetime {string}
 * @return string
 */
const convertdateToTime = datetime => (
  `${datetime.substring(datetime.indexOf('T') + 1, datetime.length - 3)}`
);

/**
 * convert date go format to date
 * @param date {string}
 * @return string
 */
const convertDate = date => (
  `${Moment(date).format('YYYY-MM-DD hh:mm A')}`
);

/**
 * convert date go format to date with seconds
 * @param date {string}
 * @return string
 */
const convertDateTime = date => {
  if (date && date === '0001-01-01T00:00:00Z') {
    return '';
  }
  return `${Moment(new Date(date)).format('YYYY-MM-DD hh:mm:ss A')}`;
};

/**
 * get difference between date and current moment in minutes
 * @param date {string}
 * @param endDate {string}
 * @return string
 */
const getDifferenceTime = (date, endDate = '') => {
  let end;
  if (endDate && endDate === '0001-01-01T00:00:00Z') {
    return '';
  }
  if (endDate) {
    end = Moment(new Date(endDate));
  } else {
    end = Moment(new Date());
  }
  const duration = Moment.duration(end.diff(date));
  let seconds = Math.round(duration.asSeconds());
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return `${hours.toString().length > 1 ? '' : '0'}${hours}:${minutes.toString().length > 1 ? '' : '0'}${minutes}:${seconds.toString().length > 1 ? '' : '0'}${seconds}`;
};

/**
 * convert info board json format to string
 * @param info {string}
 * @return string
 */
const convertBoardPlace = info => {
  if (info.length > 0) {
    const board = JSON.parse(info);
    return `${board.ReservingColor} ${board.ReservingCells ? board.ReservingCells : ''}`;
  }
};

/**
 * filter menu items by bar or remove duplicates elements
 * @param array {array}
 * @param key {string}
 * @param barId {int}
 * @return array
 */
const removeDuplicatesItems = (array, key, barId) => {
  // add filter products by bar in admin and chain manager
  if (barId) {
    return array.filter(el => el.Bar.Id === barId);
  }
  return array.reduce((accumulator, element) => {
    if (!accumulator.find(el => el[key] === element[key])) {
      accumulator.push(element);
    }
    return accumulator;
  }, []);
};

/**
 * generate main menu
 * @param role {string}
 * @param url {string}
 * @return array
 */
const generateMainMenu = (url, role) => {
  let list;
  if (role === 'Admin') {
    list = [
      {
        class: 'statistics',
        label: 'Statistics',
        link: `${url}/statistics`
      },
      {
        class: 'orders',
        label: 'Orders',
        link: `${url}/orders`
      },
      {
        class: 'menu',
        label: 'Menu',
        showDropdown: false,
        dropdown: [
          {
            label: 'Menu Items',
            link: `${url}/menu-items`
          },
          {
            label: 'Menu Categories',
            link: `${url}/menu-categories`
          },
          {
            label: 'Discounts',
            link: `${url}/discounts`
          }
        ]
      },
      {
        class: 'equipment',
        label: 'Equipment',
        link: `${url}/equipment`
      },
      {
        class: 'bars',
        label: 'Bars',
        link: `${url}/bars`
      },
      {
        class: 'barChains',
        label: 'Bar Chains',
        link: `${url}/bar-chains`
      },
      {
        class: 'users',
        label: 'Employees',
        showDropdown: false,
        dropdown: [
          {
            label: 'Bartenders',
            link: `${url}/bartenders`
          },
          {
            label: 'Bar Managers',
            link: `${url}/bar-managers`
          },
          {
            label: 'Bar Chain Managers',
            link: `${url}/bar-chain-managers`
          }
        ]
      },
      {
        class: 'status',
        label: 'System Status',
        link: `${url}/system-status`
      },
    ];
  } else if (role === 'ChainManager') {
    list = [
      {
        class: 'statistics',
        label: 'Statistics',
        link: `${url}/statistics`
      },
      {
        class: 'orders',
        label: 'Orders',
        link: `${url}/orders`
      },
      {
        class: 'menu',
        label: 'Menu',
        showDropdown: false,
        dropdown: [
          {
            label: 'Menu Items',
            link: `${url}/menu-items`
          },
          {
            label: 'Menu Categories',
            link: `${url}/menu-categories`
          },
          {
            label: 'Discounts',
            link: `${url}/discounts`
          }
        ]
      },
      {
        class: 'equipment',
        label: 'Equipment',
        link: `${url}/equipment`
      },
      {
        class: 'bars',
        label: 'Bars',
        link: `${url}/bars`
      },
      {
        class: 'users',
        label: 'Employees',
        showDropdown: false,
        dropdown: [
          {
            label: 'Bartenders',
            link: `${url}/bartenders`
          },
          {
            label: 'Bar Managers',
            link: `${url}/bar-managers`
          }
        ]
      },
      {
        class: 'status',
        label: 'System Status',
        link: `${url}/system-status`
      },
    ];
  } else if (role === 'BarManager') {
    list = [
      {
        class: 'statistics',
        label: 'Statistics',
        link: `${url}/statistics`
      },
      {
        class: 'orders',
        label: 'Orders',
        link: `${url}/orders`
      },
      {
        class: 'menu',
        label: 'Menu',
        showDropdown: false,
        dropdown: [
          {
            label: 'Menu Items',
            link: `${url}/menu-items`
          },
          {
            label: 'Menu Categories',
            link: `${url}/menu-categories`
          },
          {
            label: 'Discounts',
            link: `${url}/discounts`
          }
        ]
      },
      {
        class: 'equipment',
        label: 'Equipment',
        link: `${url}/equipment`
      },
      {
        class: 'bars',
        label: 'Bars',
        link: `${url}/bars`
      },
      {
        class: 'users',
        label: 'Employees',
        showDropdown: false,
        dropdown: [
          {
            label: 'Bartenders',
            link: `${url}/bartenders`
          }
        ]
      },
      {
        class: 'status',
        label: 'System Status',
        link: `${url}/system-status`
      },
    ];
  } else {
    list = [
      {
        class: 'menu',
        label: 'My Menu',
        showDropdown: true,
        dropdown: [
          {
            label: 'Menu Items',
            link: `${url}/menu-items`
          },
          {
            label: 'Menu Categories',
            link: `${url}/menu-categories`
          }
        ]
      },
    ];
  }
  return list;
};

export {
  updateObject,
  decimalMiddleware,
  cskCipher,
  dataGenetateForTable,
  dataSearch,
  generateUrlImage,
  convertPrice,
  convertdateToTime,
  convertDate,
  convertDateTime,
  getDifferenceTime,
  generateName,
  convertBoardPlace,
  generateMainMenu,
  removeDuplicatesItems
};
