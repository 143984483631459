import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  categories: [],
  barCategories: [],
  categoriesByBar: [],
  categoriesWithItems: [],
  types: [],
  error: null,
  loading: false,
  listTypes: [],
};

const getAllCategoriesStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getAllCategoriesSuccess = (state, action) => (
  updateObject(state, {
    categories: action.categories !== null ? action.categories : [],
    types: action.types,
    loading: false
  })
);

const getBarCategoriesSuccess = (state, action) => (
  updateObject(state, {
    barCategories: action.barCategories !== null ? action.barCategories : [],
    types: action.types !== null ? action.types : [],
    loading: false
  })
);

const getAllCategoriesByBarSuccess = (state, action) => (
  updateObject(state, {
    categoriesByBar: action.categories !== null ? action.categories : [],
    loading: false
  })
);

const getCategoriesWithItemsSuccess = (state, action) => (
  updateObject(state, {
    categoriesWithItems: action.categories !== null ? action.categories : [],
    listTypes: action.listTypes !== null ? action.listTypes : [],
    loading: false
  })
);

const getAllCategoriesFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const createCategoryStart = state => (
  updateObject(state, {
    error: null,
    loading: true
  })
);

const createCategorySuccess = state => (
  updateObject(state, {
    loading: false
  })
);

const createCategoryFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleandCategoriesError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.ADMIN_GET_ALL_CATEGORIES_START:
        return getAllCategoriesStart(state, action);
      case actionTypes.ADMIN_GET_ALL_CATEGORIES_SUCCESS:
        return getAllCategoriesSuccess(state, action);
      case actionTypes.ADMIN_GET_BAR_CATEGORIES_SUCCESS:
        return getBarCategoriesSuccess(state, action);
      case actionTypes.ADMIN_GET_CATEGORIES_WITH_ITEMS_SUCCESS:
        return getCategoriesWithItemsSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_CATEGORIES_BY_BAR_SUCCESS:
        return getAllCategoriesByBarSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_CATEGORIES_FAIL:
        return getAllCategoriesFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_ALL_CATEGORIES_ERROR:
        return cleandCategoriesError(state, action);
      case actionTypes.CREATE_CATEGORY_START:
        return createCategoryStart(state, action);
      case actionTypes.CREATE_CATEGORY_SUCCESS:
        return createCategorySuccess(state, action);
      case actionTypes.CREATE_CATEGORY_FAIL:
        return createCategoryFail(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
