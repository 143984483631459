import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from '../../components/Header/Header';
import classes from './ContentLayout.css';

import Aux from '../Aux/Aux';

class ContentLayout extends Component {
  render() {
    return (
      <Aux>
        <div className={classes.ContentLayout}>
          <main>
            <Header
              navList={null}
              dropdownOpen={false}
              toggleDropdown={false}
              userInfo={null}
            />
            <div className={classes.Content}>
              <div>
                {this.props.children}
              </div>
            </div>
          </main>
        </div>
      </Aux>
    );
  }
}

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentLayout;
