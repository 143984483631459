import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  showMenu: false,
  error: null,
  loading: false
};

const cleanUserDataError = state => (
  updateObject(state, {
    error: null
  })
);

const getLoadingStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getShowMenuSuccess = (state, action) => (
  updateObject(state, {
    showMenu: action.data.showMenu,
    loading: false
  })
);

const getLoadingFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.CLEAN_GET_PROFILE_DATA_ERROR: return cleanUserDataError(state, action);
      case actionTypes.GET_USER_LOADING_START: return getLoadingStart(state, action);
      case actionTypes.GET_USER_SHOW_MENU_SUCCESS: return getShowMenuSuccess(state, action);
      case actionTypes.GET_USER_LOADING_FAIL: return getLoadingFail(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
