import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  bars: [],
  error: null,
  loading: false
};

const getAllBarsStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getAllBarsSuccess = (state, action) => (
  updateObject(state, {
    bars: action.bars,
    loading: false
  })
);

const getAllBarsFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const createBarStart = state => (
  updateObject(state, {
    error: null,
    loading: true
  })
);

const createBarSuccess = state => (
  updateObject(state, {
    loading: false
  })
);

const createBarFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleanBarsError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.ADMIN_GET_ALL_BARS_START:
        return getAllBarsStart(state, action);
      case actionTypes.ADMIN_GET_ALL_BARS_SUCCESS:
        return getAllBarsSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_BARS_FAIL:
        return getAllBarsFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_ALL_BARS_ERROR:
        return cleanBarsError(state, action);
      case actionTypes.CREATE_BAR_START:
        return createBarStart(state, action);
      case actionTypes.CREATE_BAR_SUCCESS:
        return createBarSuccess(state, action);
      case actionTypes.CREATE_BAR_FAIL:
        return createBarFail(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
