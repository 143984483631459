/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';
import { logout } from './auth';

const getDiscountsLoading = () => ({
  type: actionTypes.DISCOUNTS_LOADING
});

const getAllDiscountsSuccess = (discounts) => ({
  type: actionTypes.ADMIN_GET_ALL_DISCOUNTS_SUCCESS,
  discounts
});

const getDiscountsError = error => ({
  type: actionTypes.DISCOUNT_ERROR,
  error
});

export const cleanDiscountsError = () => ({
  type: actionTypes.CLEAN_DISCOUNT_ERROR
});

const createDiscountSuccess = () => ({
  type: actionTypes.CREATE_DISCOUNT_SUCCESS,
});

export const getAllDiscounts = token => dispatch =>
  // eslint-disable-next-line compat/compat,no-new
  new Promise((resolve, reject) => {
    dispatch(getDiscountsLoading());
    axios.get(`${API_URL}/admin/discounts`, {
      headers: {
        'X-ACCESS-TOKEN': token
      }
    })
      .then(({ data }) => {
        dispatch(getAllDiscountsSuccess(data.discounts));
        return resolve();
      })
      .catch(({ response }) => {
        console.log(response);
        dispatch(getDiscountsError(response ? response.message : 'Server is not responding'));
        if (response !== undefined && response.status === 403) {
          dispatch(logout());
        }
        reject(response ? response.message : 'Server is not responding');
      });
  });

export const createDiscountByBar = (
  id,
  name,
  price,
  eventStart,
  eventEnd,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(getDiscountsLoading());
  const createData = {
    name,
    price: parseInt(price, 10),
    eventStart,
    eventEnd,
  };
  const url = id ? `${API_URL}/admin/update-discount/${id}` : `${API_URL}/admin/create-discount`;
  axios.post(url, createData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(({ data }) => {
      dispatch(createDiscountSuccess());
      dispatch(getAllDiscounts(token));
      return callback(data.discount);
    })
    .catch(error => dispatch(getDiscountsError(error.response ? error.response.data.message : 'Server is not responding')));
};

export const createDiscount = (
  id,
  name,
  price,
  eventStart,
  eventEnd,
  items,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(getDiscountsLoading());
  const discounts = [];
  const createData = {
    name,
    price: parseInt(price, 10),
    eventStart,
    eventEnd,
  };
  items.map(item => {
    const data = {};
    Object.assign(data, createData);
    data.productId = item.value;
    discounts.push(data);
    return null;
  });
  const url = id ? `${API_URL}/admin/update-discount/${id}` : `${API_URL}/admin/create-discount`;
  axios.post(url, discounts, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(({ data }) => {
      dispatch(createDiscountSuccess());
      dispatch(getAllDiscounts(token));
      return callback(data.discount);
    })
    .catch(error => dispatch(getDiscountsError(error.response ? error.response.data.message : 'Server is not responding')));
};

export const deleteDiscount = (
  id,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(getDiscountsLoading());
  const url = `${API_URL}/admin/delete-discount/${id}`;
  axios.get(url, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(({ data }) => {
      dispatch(createDiscountSuccess());
      dispatch(getAllDiscounts(token));
      return callback(data.discount);
    })
    .catch(error => dispatch(getDiscountsError(error.response ? error.response.data.message : 'Server is not responding')));
};
