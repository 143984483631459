import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Header from '../../components/Header/Header';
import classes from './AdminLayout.css';

import Aux from '../Aux/Aux';
import Sidebar from '../../components/Admin/Sidebar/Sidebar';
import { generateUrlImage, generateMainMenu } from '../../utils/utils';

class AdminLayout extends Component {
  state = {
    navList: [],
    headerList: [
      {
        link: '/sign-in',
        label: 'Login'
      },
      {
        link: '/sign-up',
        label: 'register'
      },
    ],
    dropdownOpen: false,
    sidebarShow: true
  };
  componentWillMount() {
    if (this.props.userInfo) {
      const navList = generateMainMenu(
        this.props.match.url,
        this.props.userInfo.role
      );
      this.setState({ navList });
    }
  }
  handleDropdown(label) {
    let count = null;
    this.state.navList.filter((item, key) => {
      if (item.label === label) {
        count = key;
      }
      return item;
    });
    if (count !== null) {
      const navlist = [...this.state.navList];
      navlist[count] = { ...navlist[count], showDropdown: !navlist[count].showDropdown };
      this.setState({ navList: navlist });
    }
  }
  render() {
    const {
      headerList, dropdownOpen, navList, sidebarShow
    } = this.state;
    return (
      <Aux>
        <div className={classes.AdminLayout}>
          <main>
            <Header
              navList={headerList}
              dropdownOpen={dropdownOpen}
              toggleDropdown={() => this.setState({ dropdownOpen: !this.state.dropdownOpen })}
              userInfo={this.props.userInfo}
            />
            <div className={classes.ALContent}>
              <aside>
                <Sidebar
                  navList={navList}
                  show={sidebarShow}
                  handleToggle={() => this.setState({ sidebarShow: !this.state.sidebarShow })}
                  handleDropdownToggle={(label) => this.handleDropdown(label)}
                  path={this.props.location.pathname.split('/')}
                />
              </aside>
              <article>
                {this.props.children}
              </article>
            </div>
          </main>
        </div>
      </Aux>
    );
  }
}

AdminLayout.propTypes = {
  children: PropTypes.node.isRequired,
  match: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  userInfo: PropTypes.shape().isRequired
};

const mapStateToProps = state => ({
  isAuth: state.auth.authKey !== null,
  isAdmin: state.auth.role === 0,
  userInfo: {
    name: `${state.auth.firstName} ${state.auth.lastName}`,
    image: state.auth.image ? generateUrlImage(state.auth.image, 'users') : null,
    role: state.auth.role
  }
});

export default connect(mapStateToProps)(withRouter(AdminLayout));
