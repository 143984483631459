import bg from './login-bg.jpg';
import logo from './logo.svg';
import marker from './marker.svg';

import starFull from './star_full.svg';
import starEmpty from './star_empty.svg';

import uploadBg from './upload-bg.jpg';

// eslint-disable-next-line import/prefer-default-export
export { bg, logo, marker, starFull, starEmpty, uploadBg };
