/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';
import { logout } from './auth';

const getAllDevicesStart = () => ({
  type: actionTypes.ADMIN_GET_ALL_DEVICES_START
});

const getAllDevicesSuccess = (devices, listTypes) => ({
  type: actionTypes.ADMIN_GET_ALL_DEVICES_SUCCESS,
  devices,
  listTypes
});

const getAllDevicesFail = error => ({
  type: actionTypes.ADMIN_GET_ALL_DEVICES_FAIL,
  error
});

export const cleanDevicesError = () => ({
  type: actionTypes.CLEAN_ADMIN_GET_ALL_DEVICES_ERROR
});

const createDeviceStart = () => ({
  type: actionTypes.CREATE_DEVICE_START
});

const createDeviceSuccess = () => ({
  type: actionTypes.CREATE_DEVICE_SUCCESS
});

const createDeviceFail = error => ({
  type: actionTypes.CREATE_DEVICE_FAIL,
  error
});

export const getAllDevices = token => dispatch => {
  dispatch(getAllDevicesStart());
  axios.get(`${API_URL}/admin/devices`, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => dispatch(getAllDevicesSuccess(data.devices, data.listTypes)))
    .catch(error => {
      dispatch(getAllDevicesFail(error.response ? error.response.data.message : 'Server is not responding'));
      if (error.status === 403) {
        dispatch(logout());
      }
    });
};

export const createDevice = (
  GCloudDeviceId,
  deviceType,
  barId,
  location,
  status,
  rows,
  cols,
  token,
  deviceId,
  callback = () => undefined
) => dispatch => {
  dispatch(createDeviceStart());
  const info = JSON.stringify({
    rows: parseInt(rows, 10),
    cols: parseInt(cols, 10)
  });
  const createData = {
    GCloudDeviceId,
    type: deviceType,
    bar_id: barId,
    location,
    status,
    info,
  };
  const url = deviceId ? `${API_URL}/admin/update-device/${deviceId}` : `${API_URL}/admin/create-device`;
  axios.post(url, createData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(() => {
      dispatch(createDeviceSuccess());
      dispatch(getAllDevices(token));
      return callback(deviceId);
    })
    .catch(error => dispatch(createDeviceFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const deleteDevice = (
  id,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(getAllDevicesStart());
  const url = `${API_URL}/admin/delete-device/${id}`;
  axios.get(url, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      dispatch(getAllDevices(token));
      return callback(data);
    })
    .catch(error => dispatch(createDeviceFail(error.response ? error.response.data.message : 'Server is not responding')));
};
