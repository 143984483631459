/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';
import { logout } from './auth';

const getAllBarsStart = () => ({
  type: actionTypes.ADMIN_GET_ALL_BARS_START
});

const getAllBarsSuccess = bars => ({
  type: actionTypes.ADMIN_GET_ALL_BARS_SUCCESS,
  bars
});

const getAllBarsFail = error => ({
  type: actionTypes.ADMIN_GET_ALL_BARS_FAIL,
  error
});

export const cleanBarsError = () => ({
  type: actionTypes.CLEAN_ADMIN_GET_ALL_BARS_ERROR
});

const createBarStart = () => ({
  type: actionTypes.CREATE_BAR_START
});

const createBarSuccess = () => ({
  type: actionTypes.CREATE_BAR_SUCCESS
});

const createBarFail = error => ({
  type: actionTypes.CREATE_BAR_FAIL,
  error
});

export const getAllBars = token => dispatch => {
  dispatch(getAllBarsStart());
  axios.get(`${API_URL}/admin/bars`, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => dispatch(getAllBarsSuccess(data.bars)))
    .catch(error => {
      dispatch(getAllBarsFail(error.response ? error.response.data.message : 'Server is not responding'));
      if (error.status === 403) {
        dispatch(logout());
      }
    });
};

export const createBar = (
  title,
  description,
  barChainId,
  latitude,
  longitude,
  image,
  stripeSecretKey,
  stripePublicKey,
  serviceFee,
  convenienceFee,
  token,
  barId,
  callback = () => undefined
) => dispatch => {
  dispatch(createBarStart());
  const createData = {
    title,
    description,
    parent_id: barChainId,
    image,
    stripeSecretKey,
    stripePublicKey,
    serviceFee,
    convenienceFee,
    latitude: latitude.toString(),
    longitude: longitude.toString(),
  };
  const url = barId ? `${API_URL}/admin/update-bar/${barId}` : `${API_URL}/admin/create-bar`;
  axios.post(url, createData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(() => {
      dispatch(createBarSuccess());
      dispatch(getAllBars(token));
      return callback(title);
    })
    .catch(error => dispatch(createBarFail(error.response ? error.response.data.message : 'Server is not responding')));
};
