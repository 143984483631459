import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Aux from '../Aux/Aux';
import classes from './Layout.css';
import Header from '../../components/Header/Header';
import { bg } from '../../assets/img/img';
// import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
// import Spinner from '../../components/UI/Spinner/Spinner';

class Layout extends Component {
  state = {
    navList: [
      {
        link: '/',
        label: 'home'
      },
      {
        link: '/sign-in',
        label: 'Login'
      },
      {
        link: '/sign-up',
        label: 'register'
      },
    ],
  };
  render() {
    return (
      <Aux>
        <div className={classes.Layout}>
          <main className={classes.Content}>
            <div className={classes.LeftSide} style={{ backgroundImage: `url(${bg})` }} />
            <div className={classes.RightSide}>
              <div className={classes.RightSideInner}>
                <Header
                  navList={this.state.navList}
                  dropdownOpen={false}
                  toggleDropdown={() => undefined}
                  userInfo={{}}
                />
                {this.props.children}
              </div>
            </div>
          </main>
        </div>
      </Aux>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

const mapStateToProps = state => ({
  isAuth: state.auth.authKey !== null
});

export default connect(mapStateToProps)(Layout);
