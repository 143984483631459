/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';

const getStatisticsStart = () => ({
  type: actionTypes.ADMIN_GET_STATISTICS_START
});

const getStatisticsSuccess =
  (doneAmount, cancelAmount, total, tipsList, sellingItems, filterDate) => ({
    type: actionTypes.ADMIN_GET_STATISTICS_SUCCESS,
    doneAmount,
    cancelAmount,
    total,
    tipsList,
    sellingItems,
    filterDate
  });

const getStatisticsFail = error => ({
  type: actionTypes.ADMIN_GET_STATISTICS_FAIL,
  error
});

export const cleanStatisticsError = () => ({
  type: actionTypes.CLEAN_ADMIN_GET_STATISTICS_ERROR
});


export const getStatistics = (
  barId,
  time,
  token,
  callback = () => undefined
) => dispatch => {
  dispatch(getStatisticsStart());
  const data = {
    bar_id: barId,
    time,
  };
  const url = `${API_URL}/admin/statistics`;
  axios.post(url, data, { headers: { 'X-ACCESS-TOKEN': token } })
    .then((res) => {
      dispatch(getStatisticsSuccess(
        res.data.doneAmount,
        res.data.cancelAmount,
        res.data.total,
        res.data.tipsList,
        res.data.sellingItems,
        res.data.filterDate
      ));
      return callback();
    })
    .catch(error => dispatch(getStatisticsFail(error.response ? error.response.data.message : 'Server is not responding')));
};
