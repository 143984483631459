/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import * as actionTypes from './actionTypes';
import { API_URL } from '../../utils/const';
import { logout } from './auth';

const getAllUsersStart = () => ({
  type: actionTypes.ADMIN_GET_ALL_USERS_START
});

const getAllUsersSuccess = users => ({
  type: actionTypes.ADMIN_GET_ALL_USERS_SUCCESS,
  users
});

const getAllBartendersSuccess = bartenders => ({
  type: actionTypes.ADMIN_GET_ALL_BARTENDERS_SUCCESS,
  bartenders
});

const getAllUsersFail = error => ({
  type: actionTypes.ADMIN_GET_ALL_USERS_FAIL,
  error
});

export const cleanUsersError = () => ({
  type: actionTypes.CLEAN_ADMIN_GET_ALL_USERS_ERROR
});

const getAccountStart = () => ({
  type: actionTypes.ADMIN_GET_ACCOUNT_START
});

const getAccountSuccess = account => ({
  type: actionTypes.ADMIN_GET_ACCOUNT_SUCCESS,
  account
});

const getAccountFail = error => ({
  type: actionTypes.ADMIN_GET_ACCOUNT_FAIL,
  error
});

const createStart = () => ({
  type: actionTypes.CREATE_START
});

const createSuccess = () => ({
  type: actionTypes.CREATE_SUCCESS,
});

const createFail = error => ({
  type: actionTypes.CREATE_FAIL,
  error
});

export const getAllUsers = token => dispatch =>
  // eslint-disable-next-line compat/compat,no-new
  new Promise((resolve, reject) => {
    dispatch(getAllUsersStart());
    axios.get(`${API_URL}/admin/managers`, {
      headers: {
        'X-ACCESS-TOKEN': token
      }
    })
      .then(({ data }) => {
        dispatch(getAllUsersSuccess(data.users));
        return resolve();
      })
      .catch(error => {
        dispatch(getAllUsersFail(error.response ? error.response.data.message : 'Server is not responding'));
        if (error.status === 403) {
          dispatch(logout());
        }
        reject(error.response ? error.response.data.message : 'Server is not responding');
      });
  });

export const getAllBartenders = token => dispatch => {
  dispatch(getAllUsersStart());
  axios.get(`${API_URL}/admin/bartenders`, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => dispatch(getAllBartendersSuccess(data.bartenders)))
    .catch(error => {
      dispatch(getAllUsersFail(error.response ? error.response.data.message : 'Server is not responding'));
      if (error.status === 403) {
        dispatch(logout());
      }
    });
};

export const create = (
  firstname,
  lastname,
  bar,
  role,
  email,
  phone,
  image,
  facebookLink,
  instagramLink,
  favorites,
  token,
  userId,
  callback = () => undefined
) => dispatch => {
  dispatch(createStart());
  const createData = {
    first_name: firstname,
    last_name: lastname,
    bar_id: bar,
    role_id: role,
    image,
    email,
    phone,
    facebookLink,
    instagramLink,
    favorites,
  };
  const url = userId ? `${API_URL}/admin/update-user/${userId}` : `${API_URL}/admin/create-user`;
  axios.post(url, createData, { headers: { 'X-ACCESS-TOKEN': token } })
    .then(() => {
      dispatch(createSuccess());
      dispatch(getAllUsers(token));
      return callback(email);
    })
    .catch(error => dispatch(createFail(error.response ? error.response.data.message : 'Server is not responding')));
};

export const getAccount = token => dispatch =>
  // eslint-disable-next-line compat/compat
  new Promise((resolve, reject) => {
    dispatch(getAccountStart());
    axios.get(`${API_URL}/admin/account`, {
      headers: {
        'X-ACCESS-TOKEN': token
      }
    })
      .then(({ data }) => {
        dispatch(getAccountSuccess(data.account));
        return resolve();
      })
      .catch(error => {
        dispatch(getAccountFail(error.response ? error.response.data.message : 'Server is not responding'));
        if (error.status === 403) {
          dispatch(logout());
        }
        reject(error.response ? error.response.data.message : 'Server is not responding');
      });
  });

export const deleteUser = (
  id,
  token,
  role,
  callback = () => undefined
) => dispatch => {
  dispatch(getAllUsersStart());
  const url = `${API_URL}/admin/delete-user/${id}`;
  axios.get(url, {
    headers: {
      'X-ACCESS-TOKEN': token
    }
  })
    .then(({ data }) => {
      if (role === 'bartender') {
        dispatch(getAllBartenders(token));
      } else {
        dispatch(getAllUsers(token));
      }
      return callback(data);
    })
    .catch(error => dispatch(createFail(error.response ? error.response.data.message : 'Server is not responding')));
};
