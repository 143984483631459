import React, { Component } from 'react';
import classes from './ContentPage.css';

class ContentPage extends Component {
  render() {
    return (
      <div className={classes.ContentPage}>
        <h2>ONLINE PRIVACY POLICY AGREEMENT</h2>
        <p>October 4, 2019</p>
        <p>SmartBAR Corp (SmartBAR) values its users&apos; privacy.
          This Privacy Policy (&#39;Policy&#39;) will
          help you understand how we collect and use personal information from those who visit our
          website or make use of our online facilities and services, and what we will and will not
          do with the information we collect. Our Policy has been designed and created to ensure
          those affiliated with SmartBAR Corp of our commitment and realization of our obligation
          not only to meet, but to exceed, most existing privacy standards.
        </p>
        <p>SmartBAR Corp (SmartBAR) values its users&apos; privacy.
          This Privacy Policy (&#39;Policy&#39;) will
          help you understand how we collect and use personal information from those who visit our
          website or make use of our online facilities and services, and what we will and will not
          do with the information we collect. Our Policy has been designed and created to ensure
          those affiliated with SmartBAR Corp of our commitment and realization of our obligation
          not only to meet, but to exceed, most existing privacy standards.
        </p>
        <p>We reserve the right to make changes to this Policy at any given time.
          If you want to make
          sure that you are up to date with the latest changes, we advise you to frequently visit
          this page. If at any point in time SmartBAR Corp decides to make use of any personally
          identifiable information on file, in a manner vastly different from that which was stated
          when this information was initially collected, the user or users shall be promptly
          notified by email. Users at that time shall have the option as to whether to permit the
          use of their information in this separate manner.
        </p>
        <p>This Policy applies to SmartBAR Corp, and it governs any and all data collection and
          usage by us. Through the use of smartbarcorp.com, you are therefore consenting
          to the data wfvcollection procedures expressed in this Policy.
        </p>
        <p>Please note that this Policy does not govern the collection and use of information by
          companies that SmartBAR Corpdoes not control, nor by individuals not employed or managed
          by us. If you visit a website that we mention or link to, be sure to review its privacy
          policy before providing the site with information. It is highly recommended and suggested
          that you review the privacy policies and statements of any website you choose to use or
          frequent to better understand the way in which websites garner, make use of and share the
          information collected.
        </p>
        <p>Specifically, this Policy will inform you of the following</p>
        <p>1. What personally identifiable information is collected from you
          through our website;
        </p>
        <p>2. Why we collect personally identifiable information and the legal basis for such
          collection;
        </p>
        <p>3. How we use the collected information and with whom it may be shared;</p>
        <p>4. What choices are available to you regarding the use of your data;</p>
        <p>5. The security procedures in place to protect the misuse of your information.</p>
        <p>Information We Collect</p>
        <p>It is always up to you whether to disclose personally identifiable information to us,
          although if you elect not to do so, we reserve the right not to register you as a user or
          provide you with any products or services.
        </p>
        <p>In addition,SmartBAR Corp may have the occasion to collect non-personal anonymous
          demographic information, such as age, gender, household income, political affiliation,
          race and religion, as well as the type of browser you are using, IP address, or type of
          operating system, which will assist us in providing and maintaining superior quality
          service.
        </p>
        <p>Why We Collect Information and For How Long</p>
        <p>We are collecting your data for several reasons:</p>
        <p>- To better understand your needs and provide you
          with the services you have requested;
        </p>
        <p>- To fulfill our legitimate interest in improving our services and products;</p>
        <p>- To send you promotional emails containing information
          we think you may like when we have your consent to do so;
        </p>
        <p>- To contact you to fill out surveys or participate in other types of market research,
          when we have your consent to do so;
        </p>
        <p>- To customize our website according to your online
          behavior and personal preferences.
        </p>
        <p>The data we collect from you will be stored for no longer than necessary. The lenth of
          time we retain said information will be determined based upon the following criteria: the
          length of time your personal information remains relevant; the length of time it is
          reasonable to keep records to demonstrate that we have fulfilled our duties and
          obligations; any limitation periods within which claims might be made; any retention
          periods prescribed by law or recommended by regulators, professional bodies or
          associations; the type of contract we have with you, the existence of your consent, and
          our legitimate interest in keeping such information as stated in this Policy.
        </p>
        <p>Use of Information Collected</p>
        <p>SmartBAR Corp does not now, nor will it in the future, sell, rent or lease any of its
          customer lists and/or names to any third parties.
        </p>
        <p>SmartBAR Corp may collect and may make use of personal information to assist in the
          operation of our website and to ensure delivery of the services you need and request. At
          times, we may find it necessary to use personally identifiable information as a means to
          keep you informed of other possible products and/or services that may be available to you
          from smartbarcorp.com
        </p>
        <p>SmartBAR Corp may also be in contact with you with regards to completing surveys and/or
          research questionnaires related to your opinion of current or potential future services
          that may be offered.
        </p>
        <p>SmartBAR Corpuses various third-party social media features including but not limited to
          Facebook, Google and other interactive programs. These may collect your IP address and
          require cookies to work properly. These services are governed by the privacy policies of
          the providers and are not within SmartBAR Corp&apos;s control.
        </p>
        <p>Disclosure of Information</p>
        <p>SmartBAR Corp may not use or disclose the information provided by you except under the
          following circumstances:
          - as necessary to provide services or products you have ordered;
          - in other ways described in this Policy or to which you have otherwise consented;
          - in the aggregate with other information in such a way so that your identity cannot
          reasonably be determined;
          - as required by law, or in response to a subpoena or search warrant;
          - to outside auditors who have agreed to keep the information confidential;
          - as necessary to enforce the Terms of Service;
          - as necessary to maintain, safeguard and preserve all the rights and property ofSmartBAR
          Corp.
        </p>
        <p>Non-Marketing Purposes</p>
        <p>SmartBAR Corp greatly respects your privacy. We do maintain and reserve the right to
          contact you if needed for non-marketing purposes (such as bug alerts, security breaches,
          account issues, and/or changes in SmartBAR Corp products and services). In certain
          circumstances, we may use our website, newspapers, or other public means to post a notice.
        </p>
        <p>Children under the age of 13</p>
        <p>SmartBAR Corp&apos;s website is not directed to, and does not knowingly collect personal
          identifiable information from, children under the age of thirteen (13). If it is
          determined that such information has been inadvertently collected on anyone under the age
          of thirteen (13), we shall immediately take the necessary steps to ensure that such
          information is deleted from our system&apos;s database,
          or in the alternative, that verifiable parental consent is obtained for the use and
          storage of such information. Anyone under the age of thirteen (13) must seek and obtain
          parent or guardian permission to use this website.
        </p>
        <p>Unsubscribe or Opt-Out</p>
        <p>All users and visitors to our website have the option to discontinue receiving
          communications from us by way of email or newsletters. To discontinue or unsubscribe from
          our website please send an email that you wish to unsubscribe to alec@smartbarcorp.com. If
          you wish to unsubscribe or opt-out from any third-party websites, you must go to that
          specific website to unsubscribe or opt-out. SmartBAR Corp will continue to adhere to this
          Policy with respect to any personal information previously collected.
        </p>
        <p>Links to Other Websites</p>
        <p>Our website does contain links to affiliate and other websites.SmartBAR Corp does not
          claim nor accept responsibility for any privacy policies, practices and/or procedures of
          other such websites. Therefore, we encourage all users and visitors to be aware when they
          leave our website and to read the privacy statements of every website that collects
          personally identifiable information. This Privacy Policy Agreement applies only and solely
          to the information collected by our website.
        </p>
        <p>Notice to European Union Users</p>
        <p>SmartBAR Corp&apos;s operations are located primarily in the United States.
          If you provide
          information to us, the information will be transferred out of the European Union (EU) and
          sent to the United States. (The adequacy decision on the EU-US Privacy became operational
          on August 1, 2016. This framework protects the fundamental rights of anyone in the EU
          whose personal data is transferred to the United States for commercial purposes. It allows
          the free transfer of data to companies that are certified in the US under the Privacy
          Shield.) By providing personal information to us, you are consenting to its storage and
          use as described in this Policy.
        </p>
        <p>Your Rights as a Data Subject</p>
        <p>Under the regulations of the General Data Protection Regulation (&#39;GDPR&#39;)
          of the EU you have certain rights as a Data Subject. These rights are as follows:
        </p>
        <p>- The right to be informed: this means we must inform you of how we intend to use your
          personal data and we do this through the terms of this Policy.
        </p>
        <p>- The right of access: this means you have the right to request access
          to the data we hold about you and we must respond to those requests
          within one month. You can do this by sending an email to alec@smartbarcorp.com.
        </p>
        <p>- The right to rectification: this means that if you believe some of the date, we hold is
          incorrect, you have the right to have it corrected. You can do this by logging into your
          account with us, or by sending us an email with your request.
        </p>
        <p>- The right to erasure: this means you can request that the information we hold be
          deleted, and we will comply unless we have a compelling reason not to, in which case you
          will be informed of same. You can do this by sending an email to alec@smartbarcorp.com.
        </p>
        <p>- The right to restrict processing: this means you can change your communication
          preferences or opt-out of certain communications. You can do this by sending an email to
          alec@smartbarcorp.com.
        </p>
        <p>- The right of data portability: this means you can obtain and use the data we hold for
          your own purposes without explanation. If you wish to request a copy of your information,
          contact us at alec@smartbarcorp.com.
        </p>
        <p>- The right to object: this means you can file a formal objection with us regarding our
          use of your information with regard to third parties, or its processing where our legal
          basis is our legitimate interest in it. To do this, please send an email to
          alec@smartbarcorp.com.
        </p>
        <p>In addition to the rights above, please rest assured that we will always aim to encrypt
          and anonymize your personal information whenever possible. We also have protocols in place
          in the unlikely event that we suffer a data breach and we will contact you if your
          personal information is ever at risk. For more details regarding our security protections
          see the section below or visit our website at smartbarcorp.com.
        </p>
        <p>Security</p>
        <p>SmartBAR Corp takes precautions to protect your information. When you submit sensitive
          information via the website, your information is protected both online and offline.
          Wherever we collect sensitive information (e.g. credit card information), that information
          is encrypted and transmitted to us in a secure way. You can verify this by looking for a
          lock icon in the address bar and looking for &#39;https&#39;
          at the beginning of the address of the webpage.
        </p>
        <p>While we use encryption to protect sensitive information transmitted online, we also
          protect your information offline. Only employees who need the information to perform a
          specific job (for example, billing or customer service) are granted access to personally
          identifiable information. The computers and servers in which we store personally
          identifiable information are kept in a secure environment. This is all done to prevent any
          loss, misuse, unauthorized access, disclosure or modification of the user&apos;s personal
          information under our control.
        </p>
        <p>The company also uses Secure Socket Layer (SSL) for authentication and private
          communications to build users&apos; trust and confidence in the internet and
          website use by providing simple and secure access and communication of credit
          card and personal information.
        </p>
        <p>Acceptance of Terms</p>
        <p>By using this website, you are hereby accepting the terms and conditions stipulated
          within the Privacy Policy Agreement. If you are not in agreement with our terms
          and conditions, then you should refrain from further use of our sites.
          In addition, your continued use of our website following the posting of any updates
          or changes to our terms and conditions shall mean that you agree and acceptance
          of such changes.
        </p>
        <p>How to Contact Us</p>
        <p>If you have any questions or concerns regarding the Privacy Policy Agreement related to
          our website, please feel free to contact us at the following email, telephone number or
          mailing address.
        </p>
        <p>Email:<br />
          alec@smartbarcorp.com<br /><br />
          Telephone Number:<br />
          9044767410<br /><br />
          Mailing Address:<br />
          SmartBAR Corp<br />
          92 SW 3rd St<br />
          Miami, Florida<br />
          33130
        </p>
        <p>The data controller responsible for your personal information for the purposes of GDPR
          compliance is:<br /><br />
          UD Tech<br />
          https://udtech.co/
        </p>
      </div>
    );
  }
}

export default ContentPage;
