import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import WebFont from 'webfontloader';

import './index.css';

import App from './App';
import registerServiceWorker from './registerServiceWorker';

import rootReducer from './store/reducers';

// eslint-disable-next-line no-undef
const composeEnhancers = process.env.NODE_ENV === 'development'
// eslint-disable-next-line no-underscore-dangle
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
WebFont.load({
  google: {
    families: ['Source Sans Pro:300,400,600', 'sans-serif']
  }
});

const app = (
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('smart-bar-root'));
registerServiceWorker();
