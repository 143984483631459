import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  menuItems: [],
  bartenderItems: [],
  searchItems: [],
  error: null,
  loading: false
};

const getAllMenuItemsStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getAllMenuItemsSuccess = (state, action) => (
  updateObject(state, {
    menuItems: action.menuItems,
    loading: false
  })
);

const getBartenderItemsSuccess = (state, action) => (
  updateObject(state, {
    bartenderItems: action.items,
    loading: false
  })
);

const getAllMenuItemsFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const createMenuItemStart = state => (
  updateObject(state, {
    error: null,
    loading: true
  })
);

const createMenuItemSuccess = state => (
  updateObject(state, {
    loading: false
  })
);

const searchMenuItemsSuccess = (state, action) => (
  updateObject(state, {
    searchItems: action.menuItems,
    loading: false
  })
);

const createMenuItemFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleandMenuItemsError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.ADMIN_GET_ALL_MENU_ITEMS_START:
        return getAllMenuItemsStart(state, action);
      case actionTypes.ADMIN_GET_ALL_MENU_ITEMS_SUCCESS:
        return getAllMenuItemsSuccess(state, action);
      case actionTypes.ADMIN_GET_BARTENDER_ITEMS_SUCCESS:
        return getBartenderItemsSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_MENU_ITEMS_FAIL:
        return getAllMenuItemsFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_ALL_MENU_ITEMS_ERROR:
        return cleandMenuItemsError(state, action);
      case actionTypes.CREATE_MENU_ITEM_START:
        return createMenuItemStart(state, action);
      case actionTypes.CREATE_MENU_ITEM_SUCCESS:
        return createMenuItemSuccess(state, action);
      case actionTypes.CREATE_MENU_ITEM_FAIL:
        return createMenuItemFail(state, action);
      case actionTypes.ADMIN_SEARCH_MENU_ITEMS_SUCCESS:
        return searchMenuItemsSuccess(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
