import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  roles: [],
  error: null,
  loading: false
};

const getAllRolesStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getAllRolesSuccess = (state, action) => (
  updateObject(state, {
    roles: action.roles,
    loading: false
  })
);

const getAllRolesFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleanRolesError = state => (
  updateObject(state, {
    error: null
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.ADMIN_GET_ALL_ROLES_START:
        return getAllRolesStart(state, action);
      case actionTypes.ADMIN_GET_ALL_ROLES_SUCCESS:
        return getAllRolesSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_ROLES_FAIL:
        return getAllRolesFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_ALL_ROLES_ERROR:
        return cleanRolesError(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
