import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initialState = {
  users: [],
  bartenders: [],
  account: {},
  error: null,
  loading: false
};

const getAllUsersStart = state => (
  updateObject(state, {
    loading: true
  })
);

const getAllUsersSuccess = (state, action) => (
  updateObject(state, {
    users: action.users,
    loading: false
  })
);

const getAllBartendersSuccess = (state, action) => (
  updateObject(state, {
    bartenders: action.bartenders,
    loading: false
  })
);

const getAllUsersFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const createStart = state => (
  updateObject(state, {
    error: null,
    loading: true
  })
);

const createSuccess = state => (
  updateObject(state, {
    loading: false
  })
);

const createFail = (state, action) => (
  updateObject(state, {
    error: action.error,
    loading: false
  })
);

const cleandUsersError = state => (
  updateObject(state, {
    error: null
  })
);

const getAccountSuccess = (state, action) => (
  updateObject(state, {
    account: action.account,
    loading: false
  })
);

const reducer = (state = initialState, action) => {
  if (action) {
    switch (action.type) {
      case actionTypes.ADMIN_GET_ALL_USERS_START:
        return getAllUsersStart(state, action);
      case actionTypes.ADMIN_GET_ALL_USERS_SUCCESS:
        return getAllUsersSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_BARTENDERS_SUCCESS:
        return getAllBartendersSuccess(state, action);
      case actionTypes.ADMIN_GET_ALL_USERS_FAIL:
        return getAllUsersFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_ALL_USERS_ERROR:
        return cleandUsersError(state, action);
      case actionTypes.CREATE_START:
        return createStart(state, action);
      case actionTypes.CREATE_SUCCESS:
        return createSuccess(state, action);
      case actionTypes.CREATE_FAIL:
        return createFail(state, action);
      case actionTypes.ADMIN_GET_ACCOUNT_START:
        return getAllUsersStart(state, action);
      case actionTypes.ADMIN_GET_ACCOUNT_SUCCESS:
        return getAccountSuccess(state, action);
      case actionTypes.ADMIN_GET_ACCOUNT_FAIL:
        return getAllUsersFail(state, action);
      case actionTypes.CLEAN_ADMIN_GET_ACCOUNT_ERROR:
        return cleandUsersError(state, action);
      default:
        return state;
    }
  }

  return state;
};

export default reducer;
